import {Backdrop, SpeedDial, useTheme} from "@mui/material";
import {useEffect, useRef, useState} from "react";

let timeoutCancel = null;
let touchTimeout = null;
let flag = true;
let touchID = null;
const ControlFab = ({children, onOpen, onClose, renderChildren, ...props}) => {
  const theme = useTheme();
  const margin = 16
  const [dragging, setDragging] = useState(false);
  const [direction, setDirection] = useState(["bottom", "right"]);
  const elem = useRef(null);
  const onDragMove = (e, override) => {
    let cond = false;
    let x, y = 0;
    if (e.buttons) {
      cond = e.buttons && e.buttons === 1;
      x = e.pageX
      y = e.pageY;
    } else if (e.touches) {
      let touch = e.touches[touchID]
      cond = !!touch;
      x = touch?.pageX;
      y = touch?.pageY;
    }
    if (override || (dragging && cond)) {
      if (elem.current) {
        elem.current.style.transition = "none";
        elem.current.style.left = `${x - 20}px`;
        if (y >= window.innerHeight / 2) {
          setDirection(["bottom", "right"])
          elem.current.style.top = `${y - elem.current.offsetHeight + 20}px`
        } else {
          setDirection(["top", "right"])
          elem.current.style.top = `${y - 20}px`
        }
      }
    }
  }

  useEffect(() => {
    if (dragging) {
      flag = false;
      onClose();
      document.addEventListener("mousemove", onDragMove);
      document.addEventListener("mouseup", onDragEnd);
      document.addEventListener("touchmove", onDragMove);
      document.addEventListener("touchend", onDragEnd);
      return () => {
        document.removeEventListener("mousemove", onDragMove);
        document.removeEventListener("mouseup", onDragEnd);
        document.removeEventListener("touchmove", onDragMove);
        document.removeEventListener("touchend", onDragEnd);
      }
    } else {
      flag = true;
    }
  }, [dragging])

  const onMouseDown = (e) => {
    if (!dragging) {
      timeoutCancel = setTimeout(() => {
        setDragging(true);
        onDragMove(e, true);
        timeoutCancel = null;
      }, 500)
    }
  }

  const onMouseUp = (e) => {
    if (timeoutCancel) {
      clearTimeout(timeoutCancel);
      timeoutCancel = null;
    }
  }

  useEffect(() => {
    if (elem.current) {
      let fabIcon = elem.current;
      fabIcon.style.left = `calc(100vw - ${margin + fabIcon.offsetWidth}px)`;
      fabIcon.style.top = `calc(100vh - ${margin + fabIcon.offsetHeight}px)`;
    }
  }, [elem]);

  const onDragEnd = (e) => {
    if (e.touches && e.touches[touchID]) {
      return
    }
    let x, y;
    if (e.touches) {
      let touch = e.changedTouches[touchID]
      x = touch.pageX;
      y = touch.pageY;
    } else {
      x = e.pageX;
      y = e.pageY;
    }
    e.preventDefault()
    setDragging(false);

    let width = window.innerWidth;
    let height = window.innerHeight;
    let dir = ["bottom", "right"];
    const fabIcon = elem.current;
    fabIcon.style.transition = "";
    if (x < width / 2) {
      fabIcon.style.left = `${margin}px`
      dir[1] = "left";
    } else {
      fabIcon.style.left = `calc(100vw - ${margin + fabIcon.offsetWidth}px)`;
    }
    if (y < height / 2) {
      fabIcon.style.top = `${margin}px`
      dir[0] = "top"
    } else {
      fabIcon.style.top = `calc(100vh - ${margin + fabIcon.offsetHeight}px)`;
    }
    setDirection(dir);
    touchID = null;
  }
  const onTouchStart = (e) => {
    if (!touchID) {
      touchID = e.changedTouches[0].identifier
    }
    if (!dragging) {
      touchTimeout = setTimeout(() => {
        setDragging(true);
        onDragMove(e, true);
        touchTimeout = null;
      }, 500)
    }
  }

  const onTouchEnd = (e) => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      touchTimeout = null;
    }
  }

  return <>
    <SpeedDial
      ref={elem}
      {...props}
      onOpen={(e, reason) => {
        if (reason === "toggle" && !dragging) {
          if (!flag) {
            flag = true;
            return
          }
          onOpen();
        }
      }}
      onClose={(e, reason) => {
        if (reason !== "mouseLeave") {
          onClose()
        }
      }}
      direction={direction[0] === "top" ? "down" : "up"}
      sx={{
        position: "fixed",
        transition: theme.transitions.create("all")
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {renderChildren(direction[1] === "right" ? "left" : "right")}
    </SpeedDial>
    <Backdrop open={dragging}/>

  </>
}

export default ControlFab;