import {setStreamConfig} from "../../redux/SettingsSlice";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Slider,
  styled,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import {
  Cancel,
  CodeTwoTone,
  ColorLensTwoTone,
  HdTwoTone,
  ImageAspectRatioTwoTone,
  MemoryTwoTone,
  MouseTwoTone,
  NetworkCheck,
  OndemandVideoTwoTone,
  RefreshTwoTone,
  Save,
  Settings,
  SpeedTwoTone,
  SportsEsportsTwoTone,
  TimelineTwoTone,
  TvTwoTone,
  UnfoldMore,
  VideoSettingsTwoTone
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setClientConfig} from "../../redux/ClientSettingsSlice";

const SettingsDialog = styled(Dialog)((theme) => ({
  '& .MuiDialog-paper': {
    height: "min(100%,500px)"
  }
}))

const StyledContent = styled(DialogContent)(theme => ({
  '&.MuiDialogContent-root': {
    padding: 0,
  }
}))

const OverlaySetting = () => {
  const dispatch = useDispatch();
  const streamSettings = useSelector(state => state.settings);
  const storedClientSettings = useSelector(state => state.client_settings);
  const [settingsState, setSettingState] = useState({});
  const [clientSetting, setClientSetting] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleOpen = () => setOpen(!open);

  const openMenu = (menuId) => {
    return (e) => {
      setAnchorEl(e.currentTarget);
      setMenuOpen(menuId)
    };
  }
  useEffect(() => {
    setSettingState(streamSettings);
  }, [streamSettings]);

  useEffect(() => {
    setClientSetting(storedClientSettings);
  }, [storedClientSettings]);
  const setValue = (name, value) => {
    setSettingState({...settingsState, [name]: value})
    closeMenu()
  }
  const setMultipleSettingValue = (changedSettings) => {
    setSettingState({...settingsState, ...changedSettings});
    closeMenu();
  }
  const setClientValue = (name, value) => {
    setClientSetting({...clientSetting, [name]: value})
    closeMenu()
  }
  const closeMenu = () => {
    setMenuOpen("");
  }

  const handleHardwareAccelerationChange = () => {
    const changedValue = settingsState.hardware_acceleration === "prefer-software" ? "prefer-hardware" : "prefer-software";
    setValue('hardware_acceleration', changedValue);
  }
  const handleGamepadAutoConnectChange = () => {
    const changedValue = !clientSetting.gamepad_autoconnect;
    setClientValue('gamepad_autoconnect', changedValue);
  }
  const handleSettingsModeChange = () => {
    setClientValue("isAdvanceSetting", !clientSetting["isAdvanceSetting"])
  }

  const handleCodecPresetChange = (codec) => {
    if (codec === 'h264') {
      setMultipleSettingValue({"codec": codec, "codec_string": "avc1.64003E"})
    } else {
      setMultipleSettingValue({"codec": codec, "codec_string": "hev1.1.6.L93.B0"})
    }
  }

  const handleVideoQualityPresetSettings = (resolutionPresetValue) => {
    const resolution_map = new Map([
        ["HD", {width: 1280, height: 720}],
        ["FullHD", {width: 1920, height: 1080}],
        ["2K", {width: 2560, height: 1440}],
        ["4K", {width: 3840, height: 2160}]
      ]
    );
    const multiplierFactor = 1024 * 1024;
    const bitrate_map = new Map([
      ["HD", 10 * multiplierFactor],
      ["FullHD", 10 * multiplierFactor],
      ["2K", 15 * multiplierFactor],
      ["4K", 20 * multiplierFactor]]
    );
    console.log("res - ", resolution_map.get(resolutionPresetValue))
    setClientValue("resolution_preset", resolutionPresetValue);
    setMultipleSettingValue({
      "resolution": resolution_map.get(resolutionPresetValue),
      "bitrate": bitrate_map.get(resolutionPresetValue)
    })
  }

  const advanceSettings = () => {
    return <>
      <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
        <Tab label={"Stream"}></Tab>
        <Tab label={"Client"}></Tab>
        <Tab label={"Input"}></Tab>
        <Tab label={"About"}></Tab>
      </Tabs>
      <StyledContent scroll={"paper"}>
        {activeTab === 0 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            <ListSubheader color={"primary"}>Video quality</ListSubheader>
            <ListItemButton dense onClick={openMenu("resolution")}>
              <ListItemIcon>
                <HdTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Resolution"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.resolution?.width} x {settingsState.resolution?.height}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("framerate")}>
              <ListItemIcon>
                <SpeedTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Framerate"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.framerate} fps</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("codec")}>
              <ListItemIcon>
                <OndemandVideoTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Video Codec"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.codec.toUpperCase()}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("color_format")}>
              <ListItemIcon>
                <ColorLensTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Color"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.color_format}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setMenuOpen("bitrate")} dense>
              <ListItemIcon>
                <NetworkCheck/>
              </ListItemIcon>
              <ListItemText primary={"Bitrate"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.bitrate / (1024 * 1024)} mbps</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen === "bitrate"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setSettingState({
                    ...settingsState,
                    bitrate: v * 1024 * 1024
                  })}
                          valueLabelDisplay="auto" min={0.5} step={0.5} sx={{width: "95%"}}
                          max={25} value={settingsState.bitrate / (1024 * 1024)}/>
                </ListItemText>
              </ListItem>
            </Collapse>
            <ListSubheader color={"primary"}>Devices</ListSubheader>
            <ListItem dense>
              <ListItemIcon>
                <MemoryTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Display Adapter"}/>
              <TextField size={"small"} defaultValue={settingsState?.adapter_idx} type={'number'}
                         name={"adapter_idx"}
                         onChange={(event) => setValue("adapter_idx", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <TvTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Monitor"}/>
              <TextField size={"small"} variant={"outlined"} defaultValue={settingsState?.output_idx}
                         type={'number'}
                         name={"output_idx"}
                         onChange={(event) => setValue("output_idx", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <ImageAspectRatioTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Change Resolution"}/>
              <Checkbox size={"small"}
                        onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                        checked={settingsState.change_resolution}/>
            </ListItem>

            <ListSubheader color={"primary"}>Advanced Options</ListSubheader>
            <ListItemButton dense onClick={openMenu("idr_interval")}>
              <ListItemIcon>
                <RefreshTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"IDR Interval"}>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.idr_interval || 0} frames</Typography>

              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Source options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.source}
                         placeholder={"extra options for video source"}
                         name={"source"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"source": event.target.value}))}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Filter options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.filter}
                         placeholder={"extra options for color filter"}
                         name={"Filter"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"filter": event.target.value}))}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Processor options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.processor}
                         placeholder={"extra options for video encoder"}
                         name={"Processor"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"processor": event.target.value}))}/>
            </ListItem>
          </List>
        </>}
        {activeTab === 1 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper', minHeight: '100%'}}>
            <ListSubheader color={"primary"}>Decoder settings</ListSubheader>
            <ListItem dense>
              <ListItemIcon>
                <VideoSettingsTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Codec String"}/>
              <TextField size={"small"} defaultValue={settingsState?.codec_string}
                         name={"Codec String"}
                         onChange={(event) => setValue("codec_string", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <SpeedTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Hardware Acceleration"}/>
              <Checkbox size={"small"} onClick={() => handleHardwareAccelerationChange()}
                        checked={settingsState.hardware_acceleration === 'prefer-hardware'}/>
            </ListItem>
          </List>
        </>}
        {activeTab === 2 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper', minHeight: '100%'}}>
            <ListSubheader color={"primary"}>Mouse</ListSubheader>
            <ListItemButton dense onClick={openMenu("cursor_capture")}>
              <ListItemIcon>
                <TimelineTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Optimize for"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.cursor_capture ? "Gaming" : "Desktop"}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("movement_multiplier")}>
              <ListItemIcon>
                <MouseTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Mouse sensitivity"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.movement_multiplier} x</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen === "movement_multiplier"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setClientSetting({
                    ...clientSetting,
                    movement_multiplier: v
                  })}
                          valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                          max={5} value={clientSetting.movement_multiplier}/>
                </ListItemText>
              </ListItem>
            </Collapse>
            <ListItemButton dense onClick={openMenu("scroll_multiplier")}>
              <ListItemIcon>
                <UnfoldMore/>
              </ListItemIcon>
              <ListItemText primary={"Scroll sensitivity"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.scroll_multiplier} x</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen === "scroll_multiplier"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider
                    onChange={(e, v) => setClientSetting({...clientSetting, scroll_multiplier: v})}
                    valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                    max={5} value={clientSetting.scroll_multiplier}/>
                </ListItemText>
              </ListItem>
            </Collapse>

            <ListSubheader color={"primary"}>Keyboard</ListSubheader>
            <ListItemButton dense onClick={openMenu("client_Keyboard_type")}>
              <ListItemIcon>
                <HdTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Keyboard Type"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.client_Keyboard_type}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListSubheader color={"primary"}>Gamepad</ListSubheader>
            <ListItem dense>
              <ListItemIcon>
                <SportsEsportsTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Auto connect Gamepads"}/>
              <ListItemSecondaryAction>
                <Checkbox size={"small"} onClick={() => handleGamepadAutoConnectChange()}
                          checked={clientSetting.gamepad_autoconnect}/>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </>}
        {activeTab === 3 &&
          <Typography paddingY={5} textAlign={"center"}>Copyright Krishna Chaitanya</Typography>}
      </StyledContent>
      <Menu open={menuOpen === "resolution"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("resolution", {width: 1280, height: 720})} value="1280x720">
          1280 x 720
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 1920, height: 1080})} value="1920x1080">
          1920 x 1080
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 2560, height: 1440})} value="2560x1440">
          2560 x 1440
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 3840, height: 2160})} value="3840x2160">
          3840 x 2160
        </MenuItem>
        <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
      </Menu>
      <Menu open={menuOpen === "framerate"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("framerate", 30)}>
          30 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 60)}>
          60 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 120)}>
          120 fps
        </MenuItem>
      </Menu>

      <Menu open={menuOpen === "codec"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("codec", "h264")}>H264</MenuItem>
        <MenuItem onClick={() => setValue("codec", "hevc")}>HEVC</MenuItem>
      </Menu>
      <Menu open={menuOpen === "color_format"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("color_format", "rgb")}>RGB</MenuItem>
        <MenuItem onClick={() => setValue("color_format", "ayuv")}>AYUV</MenuItem>
        <MenuItem onClick={() => setValue("color_format", "nv12")}>NV12</MenuItem>
      </Menu>
      <Menu open={menuOpen === "idr_interval"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("idr_interval", 0)}>
          0 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 500)}>
          500 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 1000)}>
          1000 frames
        </MenuItem>
      </Menu>
      <Menu open={menuOpen === "cursor_capture"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setClientValue("cursor_capture", true)}>
          Gaming
        </MenuItem>
        <MenuItem onClick={() => setClientValue("cursor_capture", false)}>
          Desktop
        </MenuItem>
      </Menu>
      <Menu open={menuOpen === "client_Keyboard_type"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setClientValue("client_Keyboard_type", "Windows")}>Windows</MenuItem>
        <MenuItem onClick={() => setClientValue("client_Keyboard_type", "MacOS")}>MacOS</MenuItem>
      </Menu>
    </>
  }

  const simpleSettings = () => {
    return <><StyledContent scroll={"paper"}>
      <List sx={{width: '100%', bgcolor: 'background.paper'}}>
        <ListSubheader color={"primary"}>Video</ListSubheader>
        <ListItemButton dense onClick={openMenu("resolution-presets")}>
          <ListItemIcon>
            <HdTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Video"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {clientSetting.resolution_preset}</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton dense onClick={openMenu("framerate")}>
          <ListItemIcon>
            <SpeedTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Framerate"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.framerate} fps</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton dense onClick={openMenu("codec")}>
          <ListItemIcon>
            <OndemandVideoTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Video Codec"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.codec}</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>

        <ListSubheader color={"primary"}>Mouse</ListSubheader>
        <ListItemButton dense onClick={openMenu("cursor_capture")}>
          <ListItemIcon>
            <TimelineTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Optimize for"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {clientSetting.cursor_capture ? "Gaming" : "Desktop"}</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton dense onClick={openMenu("scroll_multiplier")}>
          <ListItemIcon>
            <UnfoldMore/>
          </ListItemIcon>
          <ListItemText primary={"Scroll sensitivity"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {clientSetting.scroll_multiplier} x</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <Collapse in={menuOpen === "scroll_multiplier"}>
          <ListItem dense>
            <ListItemText inset>
              <Slider
                onChange={(e, v) => setClientSetting({...clientSetting, scroll_multiplier: v})}
                valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                max={5} value={clientSetting.scroll_multiplier}/>
            </ListItemText>
          </ListItem>
        </Collapse>
        {!clientSetting.cursor_capture &&
          <> <ListItemButton dense onClick={openMenu("movement_multiplier")}>
            <ListItemIcon>
              <MouseTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Mouse sensitivity"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {clientSetting.movement_multiplier} x</Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
            <Collapse in={menuOpen === "movement_multiplier"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setClientSetting({
                    ...clientSetting,
                    movement_multiplier: v
                  })}
                          valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                          max={5} value={clientSetting.movement_multiplier}/>
                </ListItemText>
              </ListItem>
            </Collapse> </>}

        <ListSubheader color={"primary"}>Keyboard</ListSubheader>
        <ListItemButton dense onClick={openMenu("client_Keyboard_type")}>
          <ListItemIcon>
            <HdTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Keyboard Type"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {clientSetting.client_Keyboard_type}</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListSubheader color={"primary"}>Gamepad</ListSubheader>
        <ListItem dense>
          <ListItemIcon>
            <SportsEsportsTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Auto connect Gamepads"}/>
          <ListItemSecondaryAction>
            <Checkbox size={"small"} onClick={() => handleGamepadAutoConnectChange()}
                      checked={clientSetting.gamepad_autoconnect}/>
          </ListItemSecondaryAction>
        </ListItem>
        <ListSubheader color={"primary"}>Decoder</ListSubheader>
        <ListItem dense>
          <ListItemIcon>
            <ImageAspectRatioTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Change Resolution"}/>
          <Checkbox size={"small"}
                    onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                    checked={settingsState.change_resolution}/>
        </ListItem>

      </List>


      <Menu open={menuOpen === "resolution-presets"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => handleVideoQualityPresetSettings("HD")} value="HD">
          HD
        </MenuItem>
        <MenuItem onClick={() => handleVideoQualityPresetSettings("FullHD")} value="FullHD">
          Full HD
        </MenuItem>
        <MenuItem onClick={() => handleVideoQualityPresetSettings("2K")} value="2K">
          2K
        </MenuItem>
        <MenuItem onClick={() => handleVideoQualityPresetSettings("4K")} value="4K">
          4K
        </MenuItem>
        <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
      </Menu>
      <Menu open={menuOpen === "codec"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => handleCodecPresetChange("h264")}>H264</MenuItem>
        <MenuItem onClick={() => handleCodecPresetChange("hevc")}>HEVC</MenuItem>
      </Menu>
      <Menu open={menuOpen === "cursor_capture"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setClientValue("cursor_capture", true)}>
          Gaming
        </MenuItem>
        <MenuItem onClick={() => setClientValue("cursor_capture", false)}>
          Desktop
        </MenuItem>
      </Menu>
      <Menu open={menuOpen === "client_Keyboard_type"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setClientValue("client_Keyboard_type", "Windows")}>Windows</MenuItem>
        <MenuItem onClick={() => setClientValue("client_Keyboard_type", "MacOS")}>MacOS</MenuItem>
      </Menu>
    </StyledContent></>
  }

  return <>
    <IconButton onClick={toggleOpen} sx={{m: 2}}><Settings/></IconButton>
    <SettingsDialog
      fullWidth maxWidth={"sm"} open={open} onClose={toggleOpen}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          dispatch(setStreamConfig({...settingsState}));
          dispatch(setClientConfig({...clientSetting}));
          toggleOpen()
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Settings</Typography>
          <FormControlLabel
            control={<Switch
              checked={clientSetting["isAdvanceSetting"]}
              onChange={handleSettingsModeChange}
            />}
            label={<Typography variant="body2">Advanced</Typography>}
            labelPlacement="start"/>
        </Box>
      </DialogTitle>
      <>  {clientSetting["isAdvanceSetting"] ? advanceSettings() : simpleSettings()}</>

      <DialogActions>
        <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>cancel</Button>
        <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
      </DialogActions>
    </SettingsDialog>
    <Dialog open={menuOpen === "resolution_custom"}
            onClose={closeMenu}
            PaperProps={{
              component: 'form',
              onSubmit: (e) => {
                e.preventDefault();
                let formdata = new FormData(e.currentTarget);
                let dataJson = Object.fromEntries(formdata.entries());
                setValue("resolution", {width: Number(dataJson.width), height: Number(dataJson.height)})
              }
            }}

    >
      <DialogTitle>Custom Resolution</DialogTitle>
      <StyledContent>
        <TextField autoFocus sx={{m: 1}} defaultValue={settingsState?.resolution?.width} label={"Width"}
                   name={"width"}/>
        <TextField sx={{m: 1}} label={"Height"} name={"height"}
                   defaultValue={settingsState?.resolution?.height}/>
      </StyledContent>
      <DialogActions>
        <Button type={"submit"}>Save</Button>
      </DialogActions>
    </Dialog>
  </>
}
export default OverlaySetting;