const UNKNOWN = "unknown";
const MOVE = "move";
const SCROLL = "scroll";

class TouchGestures {
  touchMode = UNKNOWN;
  kbdInp;
  existingTouches = {}
  scrollMultiplier = 1;
  moveMultiplier = 1;

  constructor(kbdInp, scrollMultiplier,moveMultiplier) {
    this.kbdInp = kbdInp;
    this.scrollMultiplier = scrollMultiplier * 10;
    this.moveMultiplier = moveMultiplier;
  }

  onTouchStart = (e) => {
    e.preventDefault()
    console.log("new touch ",e.touches);
    Object.assign(this.existingTouches,e.changedTouches);
  }

  onTouchMove = (e) => {
    e.preventDefault()
    console.log("touch moved",e.touches);
      if (this.touchMode === UNKNOWN) {
        if (e.touches.length===1){
          this.touchMode = MOVE
        } else if (e.touches.length===2) {
          this.touchMode = SCROLL;
        }
      }
      if (this.touchMode === MOVE) {
        let dx = e.touches[0].pageX - this.existingTouches[0].pageX ;
        let dy = e.touches[0].pageY - this.existingTouches[0].pageY ;
        console.log("moving ",dx,dy)
        this.kbdInp.sendMouseMove({x: dx * this.moveMultiplier, y: dy * this.moveMultiplier})
        this.existingTouches[0] = e.touches[0];
      }
      if (this.touchMode === SCROLL) {
        let dx = e.touches[0].pageX - this.existingTouches[0].pageX ;
        let dy = e.touches[0].pageY - this.existingTouches[0].pageY ;
        this.kbdInp.sendMouseScroll({x: dx * this.scrollMultiplier, y: dy * this.scrollMultiplier})
        this.existingTouches[0] = e.touches[0];
        this.existingTouches[1] = e.touches[1];
      }
  }

  onTouchEnd = (e) => {
    e.preventDefault();
    if (this.touchMode === UNKNOWN && e.touches.length === 0) {
      if (Object.keys(this.existingTouches).length === 1) {
        e.preventDefault()
        this.kbdInp.sendMouseDown(0)
        this.kbdInp.sendMouseUp(0)
      } else if (Object.keys(this.existingTouches).length === 2) {
        e.preventDefault()
        this.kbdInp.sendMouseDown(2)
        this.kbdInp.sendMouseUp(2)
      }
    }
    Object.keys(e.changedTouches).forEach((key) => {
      delete this.existingTouches[e.changedTouches[key].identifier];
    })
    if (e.touches.length === 0) {
      this.touchMode = UNKNOWN
      console.log("touch End")
    }
  }
}

export default TouchGestures;